import React, { InputHTMLAttributes, useEffect, useRef, useState, useCallback } from 'react';
import { IconBaseProps } from 'react-icons/lib';
import { useField } from '@unform/core';
import  Tooltip from '../Tooltip';
import { Container, MessageError } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon: React.ComponentType<IconBaseProps>;   
    typeMask?: ''| 'phone' | 'cel'
}

const Input: React.FC<InputProps> = ({name, icon: Icon, typeMask, ...rest}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const {fieldName, defaultValue, error, registerField } = useField(name);
    const [MaskedValue, setMaskedValue] = useState<string>('');

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, [])

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
        onChangeInput();
    }, [fieldName, registerField, MaskedValue]);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, [])

    const onChangeInput = () => {
        if (typeMask === 'phone') {
            let value = inputRef?.current?.value.replace(/\D/g, '') || '';
            const cardValueReplaced = value.length < 11 ? 
                value.match(/(\d{0,2})(\d{0,4})(\d{0,4})/) || '' : 
                    value.match(/(\d{0,2})(\d{0,5})(\d{0,4})/) || '';

        if (inputRef.current) {
            inputRef.current.value = !cardValueReplaced[2] ? cardValueReplaced[1] : `(${cardValueReplaced[1]}) ${cardValueReplaced[2]}${`${cardValueReplaced[3] ? `-${cardValueReplaced[3]}` : ''}`}${`${cardValueReplaced[4] ? `-${cardValueReplaced[4]}` : ''}`}`;
        }        
        const numbers = inputRef.current?.value?.replace(/(\D)/g, '') || '';
        setMaskedValue(numbers);
        }        
    }
    
    return (
        <>
        <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
            
                <Icon />
            
            <input 
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={defaultValue} 
                ref={inputRef} 
                onChange={onChangeInput}
                {...rest} 
            />                                          

        </Container>
        {error && <MessageError><Tooltip title={error}></Tooltip></MessageError>  }
        </>
    )
};

export default Input;