import React, { InputHTMLAttributes, useEffect, useRef, useState, useCallback } from 'react';
import { useField } from '@unform/core';

import  Tooltip from '../Tooltip';
import { Container, MessageError } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    text: string
}

const Checkbox: React.FC<InputProps> = ({name, text, ...rest}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const {fieldName, defaultValue, error, registerField } = useField(name);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, [])

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'checked'
        });
    }, [fieldName, registerField]);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, [])
    
    return (
        <>
        <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>        
            
            <input 
            type="checkbox"
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={defaultValue} 
                ref={inputRef} 
                {...rest} 
            />              
            <span>{text}</span>                            

        </Container>
        {error && <MessageError><Tooltip title={error}></Tooltip></MessageError>  }
        </>
    )
};

export default Checkbox;