import React, { createContext, useCallback, useContext, useState } from 'react';
import {v4} from 'uuid';

import ToastContainer from '../components/toastContainer';

export interface ToastMessage {
    id: string;
    type?: 'success' | 'warning' | 'error' | 'info';
    title: string;
    description?: string;
    time?: number;
}

interface ToastContextData {
    addToast(message: Omit<ToastMessage, 'id'>): void;
    removeToast(id: string): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
    const [messages, setMessages] = useState<ToastMessage[]>([]);

    const addToast = useCallback(({ type, title, description, time }: Omit<ToastMessage, 'id'>) => {
        const id = v4();
        const toast = {
            id,
            type,
            title,
            description,  
            time          
        };
        setMessages(state => [...state, toast])
    }, []);

    const removeToast = useCallback((id: string) => {
        setMessages(state => state.filter(message => message.id !== id))
    }, []);
    return (
        <ToastContext.Provider value={{addToast, removeToast}}>
            {children}
            <ToastContainer messages={messages}/>
        </ToastContext.Provider>
    );
}

function useToast(): ToastContextData {
    const context = useContext(ToastContext);

    if(!context) {
        throw new Error();
    }

    return context;
}

export { ToastProvider, useToast };