import styled from "styled-components";

export const ButtonCustom = styled.button`    

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &:hover, &:focus {
        background: #9466ff;
    }

    @media (min-width: 0px) {
        height: 56px;
        font-size: 14px;
        line-height: 16px;
    }

    @media (min-width: 576px) {
        height: unset;
        font-size: 16px;
        line-height: 19px;
    }        

    border: 0;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    background: #8257e5;
    text-transform: uppercase;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    padding: 12px 24px;
`;