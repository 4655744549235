import React from 'react';
import { 
    Route as ReactDOMRoute,
    RouteProps as ReactDOMRouteProps,
} from 'react-router-dom';

interface RouteProps extends ReactDOMRouteProps {
    isPrivate?: boolean;
    component: React.ComponentType;
}


const Route: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
    return (
        <ReactDOMRoute 
        {...rest} 
            render={({ location }) => {
                return (
                    <Component />
                )
            }} />
    )
};

export default Route;