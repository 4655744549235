import styled from 'styled-components';

interface InputContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const MessageError = styled.span`
    display: flex;
    color: rgb(211, 66, 66);
    padding-top: 7px;
    font-size: 14px;
`

export const Container = styled.div<InputContainerProps>`    

    padding: 10px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;

    input {
        flex-shrink: 0;
        position: relative;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        appearance: none;
        padding: 0px;
        background: rgb(18, 18, 20);
        border: 2px solid rgb(32, 32, 36);
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease 0s;
        overflow: hidden;
    }

    input::after {
        content: "";
        position: absolute;
        width: 6px;
        height: 9px;
        border-width: 0px 2px 2px 0px;
        border-right-style: solid;
        border-bottom-style: solid;
        border-right-color: rgb(255, 255, 255);
        border-bottom-color: rgb(255, 255, 255);
        border-image: initial;
        border-top-style: initial;
        border-top-color: initial;
        border-left-style: initial;
        border-left-color: initial;
        left: 5px;
        top: -15px;
        transform: rotate(43deg);
        opacity: 0;
        transition: all 0.2s ease-in 0s;
    }

    input:checked::after {
        top: 2px;
        opacity: 1;
    }

    input:checked {
        background: rgb(130, 87, 229);
        border-color: rgb(130, 87, 229);
    }

    span {
        padding: 0px;
        color: rgb(135, 134, 139);
        font-size: 16px;
        transition: color 0.2s ease 0s;
    }
`;