import React from 'react';

interface TooltipProps {
    title: string;
}

const Tooltip: React.FC<TooltipProps> = ({title, children}) => {
    return( 
    <div>
        {children}
        <span>{title}</span>
    </div>
    )
}

export default Tooltip;