import React from 'react';
import { H1Home } from './styles';

const Home: React.FC = () => {

    return (
        <>
        <H1Home>Soluções simples, descomplicadas e inovadoras!</H1Home>
        
        </>
    )
}

export default Home;