import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import 'antd/dist/antd.css';
import GlobalStyle from './styles/global'
import './App.css';

import Routes from './routes'
import { ToastProvider } from './hooks/toast';


const App: React.FC = () => (
    <Router>
      <ToastProvider>
        <Routes /> 
      </ToastProvider>
      <GlobalStyle />
    </Router>
);

export default App;
