import React, { useEffect, useRef, useState, useCallback, SelectHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons/lib';
//todo fazer melhorias aqui depois. Estilizar de forma genérica
// import { InputGroup, InputGroupAppend, InputGroupText, Error } from './styles';
import { useField } from '@unform/core';

import  Tooltip from '../Tooltip';
import { Container, MessageError } from './styles';

// import Skeleton from 'react-loading-skeleton';

interface InputProps extends SelectHTMLAttributes<HTMLSelectElement> {
    name: string;
    icon: React.ComponentType<IconBaseProps>;   
    options: Option[];
}

interface Option {
    name: string;
    value: string;
}

const Select: React.FC<InputProps> = ({name, icon: Icon, options, ...rest}) => {
    const inputRef = useRef<HTMLSelectElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const {fieldName, defaultValue, error, registerField } = useField(name);
    const [optionsList] = useState<Option[]>(options)

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, [])

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
    }, [fieldName, registerField]);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, [])
    
    return (
        <>
        <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
            
                <Icon />
            
            <select 
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={defaultValue} 
                ref={inputRef} 
                {...rest} 
            >
                {optionsList.map((option, key) => {
                    return (
                        <option key={key} value={option.value}>{option.name}</option>
                    );
                })}

            </select>                                          

        </Container>
        {error && <MessageError><Tooltip title={error}></Tooltip></MessageError>  }
        </>
    )
};

export default Select;