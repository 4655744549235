import styled from "styled-components";


export const EmptyCartDiv1 = styled.div`
    width: 85vw;
    margin: 20px auto 50px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
`;

export const EmptyCartDiv2 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`

export const EmptyCartDiv3 = styled.div`
    display: flex;
    flex-flow: column wrap;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 50vh;
    width: 90%;
    margin: 0px auto;
    align-items: center;
`;

export const TextEmptyCart = styled.h4`
    font-family: Roboto, sans-serif;
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1;
    color: #e1e1e6;
    text-align: center;
    overflow-wrap: break-word;
    cursor: default;

    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
`;

export const ButtonReturn = styled.span`    
    button {        
        margin: 0;
    }
`;

export const MyCart = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    width: 60vw;
    max-width: 100%;
    margin: 0px auto;
    padding: 0px 1em;
`;

export const TextMyCart = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 2em;
    font-weight: 600;
    line-height: 0.9;
    color: #e1e1e6;
    cursor: default;

    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
`;

export const DivProducts = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #8257e6;
    box-shadow: rgb(0 0 0 / 10%) 1px 3px 1px;
    padding: 0.75em 1.5em 1em;
    margin-bottom: 20px;
`;

export const Product = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 120px;
    width: 100%;
    padding: 0.75em 0px 1.5em;
    border-bottom: 1px solid rgb(238, 238, 238);

    svg:hover {
        color: #fff !important;
        
    }
`

export const ProductImg = styled.img`
    width: 100px;
    height: 100px;
`

export const ProductDescription = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    align-items: flex-start;
    width: 60%;
    margin: 0px auto;
    min-height: 100px;
    padding-left: 1.25em;
    padding-right: 1.5em;
`;

export const ProductDescriptionText = styled.h1`
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 1;    
    font-weight: 600;
    text-transform: uppercase;
    color: #e1e1e6;
    margin-block-end: 0px;
    cursor: default;
`;

export const ProductDescriptionSubText = styled.h2`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1;
    letter-spacing: -0.01em;
    margin-top: 5px;
    font-weight: 500;
    color: #737380;
    cursor: default;
`;

export const ProductsDetails = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    padding: 0.75em 0px 0px;
`

export const ProductsCount = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
`

export const ProductCountText = styled.h2`
    font-family: Roboto, sans-serif;
    font-size: 14px;
    vertical-align: middle;
    line-height: 0;
    margin-right: 10px;
    color: #e1e1e6;
    cursor: default;

    display: block;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;    
    font-weight: bold;
`;

export const CountProduct = styled.div`
    display: flex;
    flex-direction: row;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
`;

export const InputQtdProducts = styled.input`
    outline: none;
    border: 1px solid rgb(189, 189, 189);
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    color: #e1e1e6;
    background: none;
    padding: 8px;
    width: 40px;
    margin: 0px 5px;
    cursor: initial;
`;

export const ProductValue = styled.h1`
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 0;
    letter-spacing: -0.05em;
    color: #e1e1e6;
    cursor: default;
`

export const ProductResume = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    width: 40vw;
    max-width: 100%;
    margin: 0px auto;
    padding: 0px 1em;
`

export const ProductResumeText = styled.h4`
    font-family: Roboto, sans-serif;
    font-size: 2em;
    font-weight: 600;
    line-height: 0.9;
    letter-spacing: -0.05em;
    color: #e1e1e6;
    cursor: default;

    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
`;

export const ProductResumeBox = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 400px;
    width: 100%;
    border: 1px solid #8257e6;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 10%) 1px 3px 1px;
    padding: 0.75em 1.5em 2em;
`;

export const ProductResumeDescription = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
`;

export const ProductDescriptionItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid rgb(189, 189, 189);
`;

export const ProductDescriptionLeftText = styled.h5`
    color: #e1e1e6;
    font-family: Poppins, sans-serif;
    font-size: 1.25em;
    font-weight: 600;
    letter-spacing: -0.03em;
    line-height: 0.9em;
    cursor: default;

    display: block;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
`

export const ProductDescriptionRigthText = styled.h5`
    color: #e1e1e6;
    font-family: Poppins, sans-serif;
    font-size: 1.25em;
    font-weight: 600;
    letter-spacing: -0.01em;
    line-height: 0.9em;
    cursor: default;

    display: block;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
`;

export const ProductResumeButtons = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;

    button {        
        margin: 0;        
    }

    a {
        font-size: 15px;
    }
`