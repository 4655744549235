import React, { useEffect, useRef, useState, useCallback, TextareaHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons/lib';
import { useField } from '@unform/core';

import  Tooltip from '../Tooltip';
import { Container, MessageError } from './styles';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string;
    icon: React.ComponentType<IconBaseProps>;   
}

const Textarea: React.FC<TextAreaProps> = ({name, icon: Icon, ...rest}) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const {fieldName, defaultValue, error, registerField } = useField(name);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, [])

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
    }, [fieldName, registerField]);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        setIsFilled(!!inputRef.current?.value);
    }, [])
    
    return (
        <>
        <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
            
                <Icon />
            
            <textarea
                rows={3}                
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={defaultValue} 
                ref={inputRef} 
                {...rest} 
            />                                          

        </Container>
        {error && <MessageError><Tooltip title={error}></Tooltip></MessageError>  }
        </>
    )
};

export default Textarea;