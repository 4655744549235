
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from 'yup';
import React, { useCallback, useRef, useState } from "react";
import { FaBuilding, FaEnvelope, FaGlobe, FaList, FaMapMarkerAlt, FaPhone, FaUser } from "react-icons/fa";
import Input from "../../components/Input";
import { Col12, Col5, Col6, Col7, Row } from "../../styles/app";
import BudgetModel from "../../model/Budget";
import getValidationErrors from "../../utils/getValidationErrors";
import Textarea from "../../components/Textarea";
import Select from "../../components/Select";
import Checkbox from "../../components/Checkbox";
import { ContainerTextRigth } from "./styles";
import api from "../../services/api";
import Response from "../../model/Response";
import { useToast } from "../../hooks/toast";
import Button from "../../components/Button";

const Budget:React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const token = 'da39a3ee5e6b4b0d3255bfef95601890afd80709';
    const { addToast } = useToast();
    const [loading, setLoading] = useState<boolean>(false);

    const onSaveBudget = useCallback(async (budget: BudgetModel) =>  {
            try {
                setLoading(true)
                const schema = Yup.object().shape({
                    name: Yup.string().required('Nome é obrigatório!'),    
                    phone: Yup.string().min(14, 'Telefone Inválido').required('Informe o Telefone!'),
                    email: Yup.string().required('Informe o email!').email('Email não é válido'),    
                    company: Yup.string().required('Informe o nome da Empresa!'),  
                    segment: Yup.string().required('Informe o segmento da Empresa!'),        
                    description: Yup.string().required('Informe mais detalhes!'),        
                })
    
                await schema.validate(budget, {
                    abortEarly: false,
                });

                api.post<Response<BudgetModel>>('eletroinfo-shared-service/budget/openSave', budget, {
                    headers: {
                        openToken: token
                    }
                })
                .then(response => {
                    setLoading(false)
                    formRef.current?.reset()
                    addToast({
                        type: 'success',
                        title: 'Orçamento enviado com sucesso!',
                        description: 'Você receberá um email de confirmação. Aguarde nosso contato em breve.',
                        time: 6000
                    })
                })
                .catch((err) => {
                    setLoading(false)
                    if (err.response) {    
                        const arraysErrors: [] = err.response.data.errors;
                        arraysErrors.forEach(error => {
                            addToast({
                                type: 'warning',
                                title: error
                            });  
                        })                                    
                    }

                })

            } catch(err:any) {
                setLoading(false)
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);
                    formRef.current?.setErrors(errors);
                    formRef.current?.getFieldRef(err.inner[0].path || '').focus();
                }                
            } 
    }, []);

    return (
        <Row style={{textAlign: 'center', padding: '45px'}}>
            <Col6>
                <Form ref={formRef} onSubmit={onSaveBudget} style={{backgroundColor: 'rgb(32, 32, 36)', padding: '30px'}}>
                    <h1 style={{paddingBottom: '20px'}}>Orçamento</h1>
                    
                    <Row>
                        <Col12>
                            <Input name="name" type="text" placeholder="Seu nome" icon={FaUser} />                        
                        </Col12>
                    </Row>
                    <Row>
                        <Col5>
                            <Input name="phone" type="text" placeholder="Telefone" typeMask="phone" icon={FaPhone} />                        
                        </Col5>                    
                        <Col7>
                            <Input name="email" type="text" placeholder="Email" icon={FaEnvelope} />                        
                        </Col7>
                    </Row>
                    <Row>
                        <Col12>
                            <Input name="company" type="text" placeholder="Empresa" icon={FaBuilding} />                        
                        </Col12>
                    </Row>
                    <Row>
                        <Col12>
                            <Input name="website" type="text" placeholder="Página Web" icon={FaGlobe} />                        
                        </Col12>
                    </Row>
                    <Row>
                        <Col12>
                            <Select name="segment" icon={FaBuilding} options={[
                                {value: "", name: 'Selecione o segmento'}, 
                                {value: "ALIMENTACAO", name: 'Alimentação'}, 
                                {value: "BELEZA", name: 'Beleza'}, 
                                {value: "CONSTRUCAO", name: 'Construção'}, 
                                {value: "CONTABILIDADE", name: 'Contabilidade'}, 
                                {value: "DECORACAO", name: 'Decoração'}, 
                                {value: "IMOVEIS", name: 'Imóveis'}, 
                                {value: "LIMPEZA", name: 'Limpeza'}, 
                                {value: "MARKETING", name: 'Marketing'}, 
                                {value: "MOVEIS", name: 'Móveis'}, 
                                {value: "SAUDE", name: 'Saúde'},
                                {value: "SEGURANCA", name: 'Segurança'},
                                {value: "TECNOLOGIA", name: 'Tecnologia'}
                                ]} />
                        </Col12>
                    </Row>
                    <Row>
                        <Col12>
                        <label style={{display: 'flex'}}>Tipo de desenvolvimento:</label>
                        <Row>
                            <Checkbox name="typeDev.app" text="Aplicativo" />
                            <Checkbox name="typeDev.ecommerce" text="Ecommerce" />
                            <Checkbox name="typeDev.site" text="Site" />
                            <Checkbox name="typeDev.managementSystem" text="Sistema de Gestão" />
                        </Row>
                        </Col12>
                    </Row>
                    <Row>
                        <Col12>
                        <label style={{display: 'flex'}}>Recursos:</label>
                        <Row>
                            <Checkbox name="resource.paymentSlip" text="Pagamento Boleto" />
                            <Checkbox name="resource.paymentPIX" text="Pagamento Pix" />
                            <Checkbox name="resource.paymentDebit" text="Pagamento Débito" />
                            <Checkbox name="resource.paymentCredit" text="Pagamento Crédito" />
                            <Checkbox name="resource.sendSMS" text="Envio SMS" />
                            <Checkbox name="resource.sendEmail" text="Envio Email" />
                            <Checkbox name="resource.sendWhatsapp" text="Envio Whatsapp" />
                        </Row>
                        </Col12>
                    </Row>
                    <Row>
                        <Col12>
                            <Textarea name="description" placeholder="Descreva de forma detalhada o que você deseja fazer" icon={FaList}  />                        
                        </Col12>
                    </Row>
                    <Row>
                        <Col12>
                            <Select name="findUs" icon={FaMapMarkerAlt} options={[{value: "FACEBOOK", name: "Facebook"}, {value: "GOOGLE", name: "Google"}, {value: "INDICACAO", name: "Indicação"}, {value: "SITE", name: "Site"}]} />

                        </Col12>
                    </Row>

                    <Row>
                        <Col12>
                            <Button type="submit" loadind={loading}>Enviar</Button>
                        </Col12>
                    </Row>
                </Form>
            </Col6>

            <Col6>
                <ContainerTextRigth>
                <h1>Será um prazer atendê-lo!</h1>
                    <br /><br /><br />
                <h4>Forneça o máximo de informações possível, assim será mais fácil entender o que deseja fazer.</h4>
                <br /><br /><br />
                <h4>Caso deseje enviar mais arquivos detalhando o projeto, nosso email é: <b>contato@dynacode.com.br</b></h4>
                <br /><br /><br />
                <h1>simplifique! <br/>Descomplique!<br/> Inove!</h1>
                </ContainerTextRigth>
            </Col6>
        </Row>
    )

}

export default Budget;