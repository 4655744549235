import React from 'react';
import {Switch} from 'react-router-dom';

import Route from './route';

import FullLayout from '../layout/FullLayout';

const Routes: React.FC  = () => (
    <Switch>
        <Route path="/" component={FullLayout} />
    </Switch>
);

export default Routes;