import { ButtonHTMLAttributes } from "react";
import { ButtonCustom } from "./styles";
import LoadingSpin from "react-loading-spin";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

interface CustomButtonProps extends ButtonProps {
    loadind?:boolean
}

const Button: React.FC<CustomButtonProps> = ({children, loadind, ...rest}) => (
    <ButtonCustom {...rest} disabled={loadind}>
        {loadind ? <LoadingSpin width='2px' size='15px' /> : children}
    </ButtonCustom>
);

export default Button;