import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
 * {
     margin: 0;
     padding: 0;
     outline: 0;
     box-sizing: border-box;
 }

 *, :after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body,
html {
    scroll-behavior: smooth;
}

 html {
    font-family: Roboto,sans-serif;
    background: #121214;
    color: #a8a8b3;
 }

 ul {
    list-style: none;
 }

blockquote, body, dd, dl, dt, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, html, iframe, legend, li, ol, p, pre, textarea, ul {
    margin: 0;
    padding: 0;
}

a, button {
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}

a {
    text-decoration: none;
}

 `;