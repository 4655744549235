import React, { useEffect } from 'react';

import { FiShoppingCart, FiTrash, FiPlusCircle, FiMinusCircle } from 'react-icons/fi';

import {
    EmptyCartDiv1,
    EmptyCartDiv2,
    EmptyCartDiv3,
    TextEmptyCart,
    ButtonReturn,
    MyCart,
    TextMyCart,
    DivProducts,
    Product,
    ProductImg,
    ProductDescription,
    ProductDescriptionText,
    ProductDescriptionSubText,
    ProductsDetails,
    ProductsCount,
    ProductCountText,
    CountProduct,
    InputQtdProducts,
    ProductValue,
    ProductResume,
    ProductResumeText,
    ProductResumeBox,
    ProductResumeDescription,
    ProductDescriptionItem,
    ProductDescriptionLeftText,
    ProductDescriptionRigthText,
    ProductResumeButtons
} from './styles'

import {
    ALogin
} from '../styles/app'
import Button from '../components/Button';

const Cart: React.FC = () => {
    const loadCartItens = useEffect(() => {

    }, [])

    return (
        <>


        
        <EmptyCartDiv1>
            <EmptyCartDiv2>
                <MyCart>
                    <TextMyCart>
                        Meu carrinho
                    </TextMyCart>
                    <DivProducts>
                        <Product>
                            <ProductImg src="https://cache.igrejaliberdade.com/templates/eletroinfo-ecommerce/software.png" />
                            <ProductDescription>
                                <ProductDescriptionText>
                                    Administração de patrimonio
                                </ProductDescriptionText>
                                <ProductDescriptionSubText>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                                </ProductDescriptionSubText>
                            </ProductDescription>
                            <a>
                                <FiTrash size={20} color={'#737380'}/>
                            </a>
                        </Product>
                        <ProductsDetails>
                            <ProductsCount>
                                <ProductCountText>
                                    Quantidade:                                    
                                </ProductCountText>
                                <CountProduct>
                                        <a>
                                            <FiMinusCircle size={26} />
                                        </a>
                                            <InputQtdProducts type='text' readOnly value={1} />
                                        <a>
                                            <FiPlusCircle size={26} />
                                        </a>
                                    </CountProduct>
                            </ProductsCount>
                            <ProductValue>
                                R$ 70,00
                            </ProductValue>
                        </ProductsDetails>
                    </DivProducts>
                </MyCart>
                <ProductResume>
                    <ProductResumeText>
                        Resumo da Compra
                    </ProductResumeText>
                    <ProductResumeBox>
                        <ProductResumeDescription>
                            <ProductDescriptionItem>
                                <ProductDescriptionLeftText>
                                    Subtotal (1 item)
                                </ProductDescriptionLeftText>
                                <ProductDescriptionRigthText>
                                    R$ 79,00
                                </ProductDescriptionRigthText>
                            </ProductDescriptionItem>
                            <ProductDescriptionItem>
                                <ProductDescriptionLeftText>
                                    Descontos
                                </ProductDescriptionLeftText>
                                <ProductDescriptionRigthText>
                                    R$ 0,00
                                </ProductDescriptionRigthText>
                            </ProductDescriptionItem>
                            <ProductDescriptionItem>
                                <ProductDescriptionLeftText>
                                    Total
                                </ProductDescriptionLeftText>
                                <ProductDescriptionRigthText>
                                    R$ 79,00
                                </ProductDescriptionRigthText>
                            </ProductDescriptionItem>

                        </ProductResumeDescription>
                        <ProductResumeButtons>
                            <Button>
                                <ALogin>
                                    Finalizar Compra
                                </ALogin>
                            </Button>
                        </ProductResumeButtons>
                    </ProductResumeBox>
                </ProductResume>




                {/* <EmptyCartDiv3>
                    <FiShoppingCart size={70} color={'#8257e5'} />
                    <TextEmptyCart>Sem produtos no carrinho</TextEmptyCart>
                    <ButtonReturn>
                        <ButtonLogin>
                            <ALogin>
                                Retornar para pagina inicial
                            </ALogin>
                        </ButtonLogin>
                    </ButtonReturn>
                </EmptyCartDiv3> */}
            </EmptyCartDiv2>
        </EmptyCartDiv1>
        </>
    );
}

export default Cart;