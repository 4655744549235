import Cart from '../pages/Cart';
import Home from '../pages/home';
import PrivacyPolicy from '../pages/privacyPolicy';
import {FiUser} from 'react-icons/fi';
import Budget from '../pages/Budget';

const EcommerceRoutes = [
    {
        path: '/orcamento',
        name: 'Orçamento',
        icon: <FiUser size={20} />,
        component: Budget,
    },
    {
        path: '/carrinho',
        name: 'Carrinho',
        icon: <FiUser size={20} />,
        component: Cart,
    },
    {
        path: '/politica-privacidade',
        name: 'Política Privacidade',
        icon: <FiUser size={20} />,
        component: PrivacyPolicy,
    },
    { 
        path: '/', 
        pathTo: '/',
        name: 'Início',
        component: Home, 
        redirect: false
    }

]

export default EcommerceRoutes;