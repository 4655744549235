import styled from 'styled-components';


interface MenuProps {
    menuVisible: boolean;
  }

export const Container = styled.div`

`;

export const Header = styled.header`
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;


    z-index: 500;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    background: #121214;
    -webkit-box-shadow: inset 0 -1px 0 #29292e;
    box-shadow: inset 0 -1px 0 #29292e;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;

    @media (min-width: 768px) {
        height: 88px;
    }

    @media (max-width: 768px) {
        height: 78px;
        background: #121214;
    }
`;

export const HeaderContainer = styled.div`
    z-index: 100;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;

    width: 1120px;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;

    @media (min-width: 600px) {
        margin: 0;
    }

    @media (max-width: 600px) {
        margin: 0 24px;
    }
`;

export const Logo = styled.span`
    @media (min-width: 900px) {
        width: 180px;
        height: 34px;
    }

    @media (max-width: 900px) {
        width: 160px;
        height: 30px;
    }
`;

export const Navigation = styled.nav`

    a:hover {
        text-decoration: none;
        color: #e1e1e6;
        border-bottom: 2px solid #8257e5;
    }

    @media (min-width: 900px) {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
    }

    @media (max-width: 900px) {
        display: none;
    }    
`;

export const NavUl = styled.ul<MenuProps>`

    height: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;

    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;

    @media (min-width: 0px) {        
        padding-left: 5px;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        flex-direction: column;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        align-items: flex-start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        justify-content: flex-start;
        position: relative;
        margin: 0 24px;        

        @media (max-width: 600px) {
            width: 100%;
            top: 88px;
            background-color: #121214;

            display: ${(props) =>
                props.menuVisible ? 'flex' : 'none'
            };
        }
    }

    @media (min-width: 900px) {
        width: unset;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        flex-direction: row;
        -webkit-align-items: center;
        -moz-box-align: center;
        align-items: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        justify-content: center;
        position: relative;
        margin: unset;
    }
`;

export const NavLi = styled.li`
    height: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;

    @media (min-width: 0px) {
        margin-left: 0;
        /* margin-top: 8px; */
        width: 100%;
        height: 56px;
        border-radius: 5px;
        background: -webkit-gradient(linear,left top,right top,from(#202024),to(rgba(32,32,36,0)));
        background: -webkit-linear-gradient(left,#202024,rgba(32,32,36,0));        


        @media (max-width: 600px) {            
            background: linear-gradient(90deg,#202024,rgba(32,32,36,0));        
            a.active {
                text-decoration: none;
                border-left: 2px solid #8257e5;
                border-bottom: 0;
                background: linear-gradient(90deg,#09090a,rgba(9,9,10,0));
                color: #fff;
                font-weight: 700;
            }

            a {
                width: 100%;
                padding-left: 24px;
            }
        }

    }

    @media (min-width: 900px) {
        height: 100%;
        border-radius: unset;
        background: unset;
        width: unset;
        
        a {
            color: #737380;            
        }

        & + & {
            margin-left: 32px;
            margin-top: 0;
        }
    }        


    a {
        text-decoration: none;
        padding: 32px 0;

        color: #737380;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        border-bottom: 2px solid transparent;

        height: 100%;
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-align-items: center;
        -moz-box-align: center;
        align-items: center;    

        @media (min-width: 600px) {
            padding-left: unset;
            border-bottom: 2px solid transparent;
            width: unset;        

            &:hover, &:focus {
                padding-left: 0;
                border-left: unset;
                background: unset;
                border-bottom: 2px solid var(--primary);
            }
        }    

        @media (max-width: 900px) {
            padding-left: 24px;
            width: 100%;

            &:hover {
                border-bottom: 0;
                border-left: 2px solid #8257e5;
                background: linear-gradient(90deg, #09090a, rgba(9, 9, 10, 0));
            }            
        }

        @media (min-width: 600px) {
            &:hover {
                text-decoration: none;
                border-bottom: 2px solid #8257e5;
                color: #fff;
            }
        }
    }

    @media (min-width: 600px) {
        a.active {
            text-decoration: none;
            border-bottom: 2px solid #8257e5;
            color: #fff;
        }
    }    
`;

export const NavLink = styled.a`
    padding: 32px 0;

    color: #737380;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    border-bottom: 2px solid transparent;

    height: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;    

    @media (min-width: 600px) {
        padding-left: unset;
        border-bottom: 2px solid transparent;
        width: unset;        

        &:hover, &:focus {
            padding-left: 0;
            border-left: unset;
            background: unset;
            border-bottom: 2px solid var(--primary);
        }
    }    

    @media (max-width: 900px) {
        padding-left: 24px;
        width: 100%;

        &:hover, &:focus {
            border-bottom: 0;
            border-left: 2px solid #8257e5;
            background: linear-gradient(90deg, #09090a, rgba(9, 9, 10, 0));
        }
    }

    @media (min-width: 320px) {
        &:hover, &:focus {
            text-decoration: none;
            border-bottom: 2px solid #8257e5;
            color: #fff;
        }
    }
`;

export const AccessUl = styled.ul`

    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;

    @media (min-width: 0px) {
        display: none;
    }

    @media (min-width: 900px) {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
    }    
`;

export const SignUp = styled.span`
    height: 100%;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: #fff;
    text-transform: uppercase;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
`;

export const BsEcommerce = styled.span`
    color: #8257e5;
    margin-left: 24px;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
`;

export const ALogin = styled.span`
    height: 100%;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: #fff;
    text-transform: uppercase;

    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;

    &:hover {
        text-decoration: none;
    }
`;

export const PageFooter = styled.footer`
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    position: relative;

    &:after {
        content: "";
        width: 100%;
        height: 1px;
        bottom: 150px;
        position: absolute;
        background: #29292e;
    }

    @media (min-width: 576px) {
        &:after {
            bottom: 112px;
        }
    }
        
`;

export const DivFooter = styled.div`
    width: 1120px;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;

    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;

    @media (min-width: 320px) {
        margin: 0 24px;
    }

    @media (min-width: 1120px) {
        margin: 0px;
    }
`;

export const FooterUp = styled.div`

    display: -webkit-flex;
    display: -moz-box;
    display: flex;

    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    padding: 80px 0;

    @media (min-width: 320px) {
        padding: 64px 0;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        flex-direction: column;
    }

    @media (min-width: 992px) {
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        flex-direction: row;
        padding: 80px 0;
    }
`;

export const FooterUpLeft = styled.div`
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;

    display: -webkit-flex;
    display: -moz-box;
    display: flex;
`;

export const FooterLinkLogo = styled.span`

`;

export const WhatsappLink = styled.a`
    z-index: 500;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    bottom: 70px;
    position: fixed;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #04d361;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    svg {
        color: #fff;
    }

    &:hover {
        -webkit-box-shadow: 0 0 0 2px #fff;
        box-shadow: 0 0 0 2px #fff;
    }

    @media (min-width: 320px) {
        right: 20px;
    }

    @media (min-width: 992px) {
        right: 24px;
    }
`;

export const NavFooter = styled.nav`
    width: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    max-width: 639px;
    @media (min-width: 320px) {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        margin-top: 64px;
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(3,1fr);
    }

    @media (min-width: 992px) {
        margin: unset;
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        justify-content: space-between;
        grid-template-columns: unset;
    }
`;

export const TitleNavFooter = styled.span`
    font-size: 16px;
    font-weight: 700;
    line-height: 36px;
    color: #e1e1e6;
`;

export const LiNavFooter = styled.li`
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
`;

export const LinkNavFooter = styled.span`
    a {

    

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 2px;
        background-color: #8257e5;
        -webkit-transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
        transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    }

    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;


    font-size: 16px;
    line-height: 36px;
    color: #a8a8b3;
    text-decoration: none !important;

    @media (hover: hover) and (pointer: fine) {
        &:hover:before {
            left: 0;
            right: 0;
            width: 100%;
        }

    }

    @media (min-width: 320px) {
        padding: 0;
        font-size: 12px;
        line-height: 35px;
    }
    @media (min-width: 375px) {
        font-size: 15px;
    }
    @media (min-width: 576px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 36px;
    }

    &:hover {
        text-decoration: none !important;
    }    
}
`;

export const Copyright = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    font-size: 13px;
    line-height: 23px;

    @media (min-width: 576px) {
        font-size: 14px;
        line-height: 24px;
    }
`;

export const FooterBottom = styled.div`
    height: 150px;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;

    @media (min-width: 576px) {
        height: 112px;
    }
`

export const FooterSocial = styled.div`
    grid-gap: 8px;
    gap: 8px;
    display: grid;

    @media (min-width: 320px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 576px) {
        grid-template-columns: repeat(6, 1fr);
    }
`;

export const LiSocial = styled.li`
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #202024;

    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;

    &:hover {
        background: #8257e5;
    }
`;

export const LinkSocial = styled.a`
    text-decoration: none;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;

    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;

    svg {
        color: #a8a8b3;
    }

    &:hover {
        text-decoration: none;
        -webkit-filter: brightness(1000%);
        filter: brightness(1000%);
    }

    @media (min-width: 320px) {
        padding: 8px;
    }

    @media (min-width: 375px) {
        padding: 10px;
    }
`;

export const MenuMobile = styled.button`    
    @media (min-width: 600px) {
        display: none;
    }
    @media (max-width: 600px) {
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        text-decoration: none;
        display: flex;        
    }

    svg {
        fill: none;
        background-color: transparent;
        color: #8257e5;        
    }
`

export const Row = styled.div`
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -7.5px;
  margin-left: -7.5px;
  margin-bottom: 1rem;
`

export const Col2 = styled.div`  
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  display: block;

  @media (min-width: 576px) {    
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  @media (max-width: 576px) {
    margin-bottom: 1rem;
  }
`

export const Col3 = styled.div`  
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  display: block;

  @media (min-width: 576px) {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (max-width: 576px) {
    margin-bottom: 1rem;
  }
`

export const Col4 = styled.div`  
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  display: block;

  @media (min-width: 576px) {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  @media (max-width: 576px) {
    margin-bottom: 1rem;
  }
`

export const Col5 = styled.div`  
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  display: block;

  @media (min-width: 576px) {
    -ms-flex: 0 0 41.666%;
    flex: 0 0 41.666%;
    max-width: 41.666%;
  }

  @media (max-width: 576px) {
    margin-bottom: 1rem;
  }
`

export const Col6 = styled.div`  
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  display: block;

  @media (min-width: 576px) {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (max-width: 576px) {
    margin-bottom: 1rem;
  }
`

export const Col7 = styled.div`  
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  display: block;

  @media (min-width: 576px) {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  @media (max-width: 576px) {
    margin-bottom: 1rem;
  }
`

export const Col10 = styled.div`
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
`;

export const Col12 = styled.section`  
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  flex: 0 0 100%;
  max-width: 100%;
`