import styled, { css } from 'styled-components';

interface InputContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const MessageError = styled.span`
    display: flex;
    color: rgb(211, 66, 66);
    padding-top: 7px;
    font-size: 14px;
`

export const Container = styled.div<InputContainerProps>`    

svg {
    color: #41414d;
    width: 15px;
    height: 15px;

    ${props => props.isFocused && css`
        color: #8257e5 !important;
    `
    }
}

    color: #8257e5;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    padding-left: 20px;
    height: 50px;
    width: 100%;
    border: 1px solid transparent !important;
    background: rgb(18, 18, 20);
    border-radius: 5px;

    ${props => props.isFocused && css`
        border-color: #8257e5 !important;
    `
    }


    ${props => props.isErrored && css`
        
    `}

    input {
        background: inherit;
        border: none;
        font-size: 18px;
        line-height: 19px;
        width: 100%;
        height: 100%;
        color: #fff;
        outline: none;
        border-radius: 5px;
        padding: 18px 20px;
        -webkit-transition: border-color 0.2s;
        transition: border-color 0.2s;
        background: rgb(18, 18, 20);

        ${props => props.isFocused && css`
            background-image: gradient(45deg, var(--primary) 50%, transparent 50%), gradient(135deg, transparent 50%, var(--primary) 50%), gradient(to right, var(--shape), var(--shape));
            background-position: -webkit-calc(100% - 15px) 28px, -webkit-calc(100% - 20px) 28px, 100% 0;
            background-position: calc(100% - 15px) 28px, calc(100% - 20px) 28px, 100% 0;
            outline: 0;            
        `}  
    }
`;