import React from 'react';
import { useTransition } from 'react-spring';
import { Container } from './styles';
import { ToastMessage } from '../../hooks/toast'
import Toast from './Toast';

interface ToastContainerProps {
    messages: ToastMessage[]
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
    const messageWithTransitions = useTransition(
        messages,
        {
            from: { right: '-120%', opacity: 0 },
            enter: { right: '0px', opacity: 1 },            
            leave: { right: '-120%', opacity: 0 },            
        }
        )

    return (
        <Container>
            {messageWithTransitions((styles, item, t, index) => (
                <Toast key={index} style={styles} message={item} />                    
            ))}
        </Container>
    )
};

export default ToastContainer;